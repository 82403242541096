import { Button, Layout, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { BaseChord } from "./chord-logic/BaseChord";
import {
  BaseChordType,
  ChordConnection,
  KeyNote,
  ProgressionItem,
} from "./chord-logic/baseChordTypes";
import { AlternativeChordsGrid } from "./components/AlternativeChordsGrid";
import { ChordInfo } from "./components/ChordInfo";
import { ChordProgressionTracker } from "./components/ChordProgressionTracker";
import { NextChordsGrid } from "./components/NextChordsGrid";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { calculateKeyFromConnection } from "./utils";
import { NavBar } from "./components/nav-bar/NavBar";
import { DEFAULT_FIRST_CHORD, DEFAULT_KEY } from "./constants";
import { Footer } from "./components/Footer";
import { SaveProgressionModal } from "./components/SaveProgressionModal";
import { ProgressionLibraryModal } from "./components/ProgressionLibraryModal";
import { SavedProgression } from "./localStorage";
import { SharingLinks } from "./SharingLinks";
import { ShareProgressionLinkModal } from "./components/ShareProgressionLinkModal";

const pageSpacing = "1rem";

const App = () => {
  const [originalKey, setOriginalKey] = useState<KeyNote>(DEFAULT_KEY);
  const [modulatedKey, setModulatedKey] = useState<KeyNote | undefined>(
    undefined
  );
  const [currentBaseChordType, setCurrentBaseChordType] =
    useState<BaseChordType>(DEFAULT_FIRST_CHORD);
  const [progressionItems, setProgressionItems] = useState<ProgressionItem[]>([
    { baseChordType: DEFAULT_FIRST_CHORD, key: DEFAULT_KEY },
  ]);
  const [isSaveProgressionModalOpen, setIsSaveProgressionModalOpen] =
    useState<boolean>(false);
  const [isProgressionLibraryModalOpen, setIsProgressionLibraryModalOpen] =
    useState<boolean>(false);
  const [isShareProgressionLinkModalOpen, setIsShareProgressionLinkModalOpen] =
    useState<boolean>(false);
  const currentKey = modulatedKey || originalKey;
  const currentBaseChord = new BaseChord(currentBaseChordType);

  const openSaveProgressionModal = () => {
    setIsSaveProgressionModalOpen(true);
  };

  const closeSaveProgressionModal = () => {
    setIsSaveProgressionModalOpen(false);
  };

  const openProgressionLibraryModal = () => {
    setIsProgressionLibraryModalOpen(true);
  };

  const closeProgressionLibraryModal = () => {
    setIsProgressionLibraryModalOpen(false);
  };

  const openShareProgressionLinkModal = () => {
    setIsShareProgressionLinkModalOpen(true);
  };

  const closeShareProgressionLinkModal = () => {
    setIsShareProgressionLinkModalOpen(false);
  };

  const resetEverything: (defaultOverrides: {
    defaultOriginalKey?: KeyNote;
    defaultProgressionItems?: ProgressionItem[];
    defaultFirstChord?: BaseChordType;
  }) => void = (defaultOverrides) => {
    const { defaultOriginalKey, defaultProgressionItems, defaultFirstChord } =
      defaultOverrides;
    const newlyDefaultKey = defaultOriginalKey || DEFAULT_KEY;
    const mostRecentBaseChordTypeOfOverrideProgression =
      defaultProgressionItems &&
      defaultProgressionItems[defaultProgressionItems.length - 1]
        ?.baseChordType;
    const mostRecentModulatedKey =
      defaultProgressionItems &&
      defaultProgressionItems[defaultProgressionItems.length - 1]?.key;
    const newlyCurrentBaseChordType =
      defaultFirstChord ||
      mostRecentBaseChordTypeOfOverrideProgression ||
      DEFAULT_FIRST_CHORD;
    setOriginalKey(newlyDefaultKey);
    setProgressionItems(
      defaultProgressionItems || [
        {
          baseChordType: DEFAULT_FIRST_CHORD,
          key: newlyDefaultKey,
        },
      ]
    );
    setCurrentBaseChordType(newlyCurrentBaseChordType);
    setModulatedKey(mostRecentModulatedKey || undefined);
  };

  const resetEverythingToDefaults = () => {
    resetEverything({});
  };

  const setOriginalKeyAndResetEverything = (newKey: KeyNote) => {
    resetEverything({ defaultOriginalKey: newKey });
  };

  const addToProgression = (baseChordType: BaseChordType, key: KeyNote) => {
    setProgressionItems(progressionItems.concat([{ baseChordType, key }]));
  };

  const loadProgression = (progression: SavedProgression) => {
    resetEverything({
      defaultOriginalKey: progression.originalKey,
      defaultProgressionItems: progression.progressionItems,
    });
  };

  const updateCurrentChordInProgression = (
    baseChordType: BaseChordType,
    key: KeyNote
  ) => {
    const newProgression = progressionItems.concat([]); // Duplicate stateful array
    newProgression.pop();
    newProgression.push({ baseChordType, key });
    setProgressionItems(newProgression);
  };

  const removeCurrentChordInProgression = () => {
    const newProgression = progressionItems.concat([]); // Duplicate stateful array
    newProgression.pop();
    setProgressionItems(newProgression);
  };

  const modulateKey = (newKey: KeyNote) => {
    if (newKey === originalKey) {
      setModulatedKey(undefined);
    } else {
      setModulatedKey(newKey);
    }
  };

  const sharedChordChangeLogic = (chordConnection: ChordConnection) => {
    if (chordConnection.keyModulationDistance) {
      modulateKey(calculateKeyFromConnection(currentKey, chordConnection));
    }
    setCurrentBaseChordType(chordConnection.baseChordType);
  };

  const onNextChordClick = (chordConnection: ChordConnection) => {
    const keyOfConnection = calculateKeyFromConnection(
      currentKey,
      chordConnection
    );
    addToProgression(chordConnection.baseChordType, keyOfConnection);
    sharedChordChangeLogic(chordConnection);
  };

  const onAlternativeChordClick = (chordConnection: ChordConnection) => {
    const keyOfConnection = calculateKeyFromConnection(
      currentKey,
      chordConnection
    );
    updateCurrentChordInProgression(
      chordConnection.baseChordType,
      keyOfConnection
    );
    sharedChordChangeLogic(chordConnection);
  };

  const onGoBackOneChord = () => {
    const previousItem = progressionItems[progressionItems.length - 2];
    setCurrentBaseChordType(previousItem.baseChordType);
    modulateKey(previousItem.key);
    removeCurrentChordInProgression();
  };

  const setFirstChordAndResetEverything = (baseChordType: BaseChordType) => {
    resetEverything({
      defaultFirstChord: baseChordType,
      defaultProgressionItems: [{ baseChordType, key: currentKey }],
    });
  };

  useEffect(() => {
    const shareLinkData = SharingLinks.detectProgressionUrl();
    if (shareLinkData.isValid) {
      resetEverything({
        defaultOriginalKey: shareLinkData.originalKey,
        defaultProgressionItems: shareLinkData.progressionItems,
      });
    }
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Header
        style={{
          paddingLeft: pageSpacing,
          paddingRight: pageSpacing,
          background: "linear-gradient(90deg, #ff6650, #f5ab51)",
        }}
      >
        <NavBar
          originalKey={originalKey}
          onKeySelect={setOriginalKeyAndResetEverything}
          onChordSelect={setFirstChordAndResetEverything}
          resetEverything={resetEverythingToDefaults}
          openSaveProgressionModal={openSaveProgressionModal}
          openProgressionLibraryModal={openProgressionLibraryModal}
          openShareProgressionLinkModal={openShareProgressionLinkModal}
        />
      </Layout.Header>
      <Layout.Content style={{ margin: pageSpacing }}>
        <ChordProgressionTracker progressionItems={progressionItems} />
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {progressionItems.length > 1 && (
            <Button
              onClick={onGoBackOneChord}
              icon={
                <ArrowLeftOutlined
                  style={{
                    color: "#dce3e8",
                    fontSize: "2rem",
                  }}
                />
              }
              type={"text"}
              size={"large"}
            />
          )}
          <div
            style={{
              backgroundColor: "#dce3e8",
              borderRadius: "2px",
              padding: "0.5rem",
            }}
          >
            <ChordInfo
              uniqueId={"current-chord-viewer"}
              baseChord={currentBaseChord}
              currentKey={currentKey}
            />
            <Typography>{`Current Key: ${currentKey}`}</Typography>
            <Typography
              style={{ fontSize: "0.6rem" }}
            >{`Original Key: ${originalKey}`}</Typography>
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <NextChordsGrid
            title={"Next Chords"}
            baseChord={currentBaseChord}
            currentKey={currentKey}
            progressionItems={progressionItems}
            onChordClick={onNextChordClick}
          />
        </div>
        <div style={{ marginTop: "2rem" }}>
          <AlternativeChordsGrid
            title={"Alternative Chords"}
            baseChord={currentBaseChord}
            currentKey={currentKey}
            progressionItems={progressionItems}
            onChordClick={onAlternativeChordClick}
          />
        </div>
      </Layout.Content>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
      <SaveProgressionModal
        isVisible={isSaveProgressionModalOpen}
        closeModal={closeSaveProgressionModal}
        progressionItems={progressionItems}
        originalKey={originalKey}
      />
      <ProgressionLibraryModal
        isVisible={isProgressionLibraryModalOpen}
        closeModal={closeProgressionLibraryModal}
        loadProgression={loadProgression}
      />
      <ShareProgressionLinkModal
        isVisible={isShareProgressionLinkModalOpen}
        closeModal={closeShareProgressionLinkModal}
        progressionItems={progressionItems}
        originalKey={originalKey}
      />
    </Layout>
  );
};

export default App;
