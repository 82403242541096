import { Card, Col, Row, Tag, Typography } from "antd";
import React from "react";
import { BaseChord } from "../chord-logic/BaseChord";
import {
  ChordConnection,
  KeyNote,
  ProgressionItem,
} from "../chord-logic/baseChordTypes";
import { calculateKeyFromConnection } from "../utils";
import { ChordInfo } from "./ChordInfo";

export interface IChordsGridProps {
  title: string;
  currentKey: KeyNote;
  chordConnections: ChordConnection[];
  progressionItems: ProgressionItem[];
  onChordClick: (chordConnection: ChordConnection) => void;
}

export const ChordsGrid: React.FunctionComponent<IChordsGridProps> = (
  props
) => {
  const {
    title,
    currentKey,
    chordConnections,
    progressionItems,
    onChordClick,
  } = props;

  return (
    <div>
      <Typography.Title level={5} style={{ color: "#9d9d9d" }}>
        {title}
      </Typography.Title>
      <Row gutter={[4, 4]}>
        {chordConnections.map((connection, index) => {
          const connectionBaseChord = new BaseChord(connection.baseChordType);
          const keyToShow = calculateKeyFromConnection(currentKey, connection);
          const uniqueId = `ChordsGrip-Item-${index}-${keyToShow}-${
            connectionBaseChord.getBaseChordInfo(keyToShow).name
          }`;

          let spiceLevelsString = "";
          if (connection.spiceLevel) {
            for (let i = 0; i < connection.spiceLevel; i++) {
              spiceLevelsString += "🌶️";
            }
          }

          const previousChordInProgression =
            progressionItems[progressionItems.length - 2];
          const isSuggestedBasedOnPrevChord =
            connection.suggestedBasedOnPrevChord?.includes(
              previousChordInProgression.baseChordType
            );

          return (
            <Col sm={12} key={uniqueId} flex={1}>
              <Card
                style={{ cursor: "pointer", height: "100%" }}
                size={"small"}
                onClick={() => onChordClick(connection)}
                hoverable
              >
                <ChordInfo
                  baseChord={connectionBaseChord}
                  currentKey={keyToShow}
                  uniqueId={uniqueId}
                />
                <Typography>{connection.description}</Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {connection.keyModulationDistance && (
                      <Tag
                        color={"#3052ec"}
                      >{`Key change to ${keyToShow}`}</Tag>
                    )}
                    {connection.isHighlyCommon && (
                      <Tag color={"#ec3030"}>{"Highly common"}</Tag>
                    )}
                    {isSuggestedBasedOnPrevChord && (
                      <Tag color={"red"}>{"Suggested"}</Tag>
                    )}
                  </div>
                  {connection.spiceLevel && (
                    <Typography>{spiceLevelsString}</Typography>
                  )}
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
