import { Progression, Chord } from "@tonaljs/tonal";
import { BaseChordType } from "./baseChordTypes";
import { generateChordMappings } from "./chordMappings";

export class BaseChord {
  private _baseChordType: BaseChordType;

  constructor(baseChordType: BaseChordType) {
    this._baseChordType = baseChordType;
  }

  public getRomanNumeralSymbol = () => {
    return this._baseChordType;
  };

  public getBaseChordInfo = (currentKey: string) => {
    const keyifiedChord = this.generateKeyifiedChord(currentKey);
    return Chord.get(keyifiedChord);
  };

  public getNextChords = () => {
    return generateChordMappings(this._baseChordType).nextChords;
  };

  public getAlternativeChords = () => {
    const alternativeChords = generateChordMappings(
      this._baseChordType
    ).alternativeChords;
    return alternativeChords.filter(
      (chord) => chord.baseChordType !== this._baseChordType
    );
  };

  private generateKeyifiedChord = (currentKey: string) => {
    return Progression.fromRomanNumerals(currentKey, [this._baseChordType])[0];
  };
}
