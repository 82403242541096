import { Select, Space } from "antd";
import React from "react";
import { KeyNote } from "../../chord-logic/baseChordTypes";
import { DEFAULT_KEY } from "../../constants";
import { getAllKeys } from "../../utils";

export interface IKeyPickerProps {
  originalKey: KeyNote;
  onKeySelect: (newKey: KeyNote) => void;
}

export const KeyPicker: React.FunctionComponent<IKeyPickerProps> = (props) => {
  const { originalKey, onKeySelect } = props;
  return (
    <Space direction={"horizontal"}>
      <Select
        defaultValue={DEFAULT_KEY}
        onChange={onKeySelect}
        value={originalKey}
      >
        {getAllKeys().map((key) => {
          return (
            <Select.Option key={`KeyPicker-KeyPicker-${key}`} value={key}>
              {`Key: ${key}`}
            </Select.Option>
          );
        })}
      </Select>
    </Space>
  );
};
