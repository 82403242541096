import { KeyNote, ProgressionItem } from "./chord-logic/baseChordTypes";

export interface SavedProgression {
  name: string;
  originalKey: KeyNote;
  progressionItems: ProgressionItem[];
}

const SAVED_PROGRESSIONS = "SAVED_PROGRESSIONS";

export class LocalStorageDB {
  static saveProgression = (newProgression: SavedProgression) => {
    const { name, originalKey, progressionItems } = newProgression;
    const existingProgressionsString = localStorage.getItem(SAVED_PROGRESSIONS);
    const existingProgressionsJson = JSON.parse(
      existingProgressionsString || "{}"
    );
    existingProgressionsJson[name] = { name, originalKey, progressionItems };
    const newProgressionsString = JSON.stringify(existingProgressionsJson);
    localStorage.setItem(SAVED_PROGRESSIONS, newProgressionsString);
  };

  static getAllSavedProgressions = (): SavedProgression[] => {
    const existingProgressionsString = localStorage.getItem(SAVED_PROGRESSIONS);
    const existingProgressionsJson = JSON.parse(
      existingProgressionsString || "{}"
    );
    return Object.values(existingProgressionsJson);
  };

  static deleteSavedProgression = (progressionName: string) => {
    const existingProgressionsString = localStorage.getItem(SAVED_PROGRESSIONS);
    const existingProgressionsJson = JSON.parse(
      existingProgressionsString || "{}"
    );
    delete existingProgressionsJson[progressionName];
    const updatedProgressionsString = JSON.stringify(existingProgressionsJson);
    localStorage.setItem(SAVED_PROGRESSIONS, updatedProgressionsString);
  };
}
