import React, { useEffect } from "react";
import abcjs from "abcjs";

interface INotationProps {
  uniqueId: string;
  abcNotation: string;
}

export const Notation: React.FunctionComponent<INotationProps> = (props) => {
  const { uniqueId, abcNotation } = props;

  // This logic must be in useEffect because notation doesn't render until component is mounted
  useEffect(() => {
    abcjs.renderAbc(uniqueId, abcNotation);
  }, [uniqueId, abcNotation]);

  return <div id={uniqueId} />;
};
