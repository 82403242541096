import React from "react";
import { BaseChord } from "../chord-logic/BaseChord";
import { ChordsGrid, IChordsGridProps } from "./ChordsGrid";

interface IAlternativeChordsGridProps
  extends Omit<IChordsGridProps, "chordConnections"> {
  baseChord: BaseChord;
}

export const AlternativeChordsGrid: React.FunctionComponent<
  IAlternativeChordsGridProps
> = (props) => {
  const { baseChord } = props;
  const chordAlternatives = baseChord.getAlternativeChords();

  if (chordAlternatives.length === 0) {
    return null;
  }

  return <ChordsGrid {...props} chordConnections={chordAlternatives} />;
};
