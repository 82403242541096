import { Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { KeyNote } from "../chord-logic/baseChordTypes";
import { LocalStorageDB } from "../localStorage";
import {
  ChordProgressionTracker,
  IChordProgressionTrackerProps,
} from "./ChordProgressionTracker";

interface ISaveProgressionModalProps extends IChordProgressionTrackerProps {
  isVisible: boolean;
  closeModal: () => void;
  originalKey: KeyNote;
}

export const SaveProgressionModal: React.FunctionComponent<
  ISaveProgressionModalProps
> = (props) => {
  const { isVisible, closeModal, originalKey, progressionItems } = props;
  const [newProgressionName, setNewProgressionName] = useState<string>("");
  const [showNameMissingError, setShowNameMissingError] =
    useState<boolean>(false);
  const onSaveNewProgression = () => {
    if (newProgressionName === "") {
      setShowNameMissingError(true);
      return;
    }
    LocalStorageDB.saveProgression({
      name: newProgressionName,
      originalKey,
      progressionItems,
    });
    notification.success({
      message: "Saved in Library",
      duration: 3,
    });
    closeModalAndCleanup();
  };

  const closeModalAndCleanup = () => {
    closeModal();
    setNewProgressionName("");
    setShowNameMissingError(false);
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={closeModalAndCleanup}
      onOk={onSaveNewProgression}
      title={"New chord progression"}
    >
      <Input
        value={newProgressionName}
        onChange={(e) => setNewProgressionName(e.target.value)}
        placeholder={"Name your chord progression"}
        status={showNameMissingError ? "error" : ""}
        style={{ marginBottom: "1rem" }}
      />
      <ChordProgressionTracker {...props} />
    </Modal>
  );
};
