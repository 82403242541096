import { Button, Drawer, Grid, Space, Typography } from "antd";
import React, { useState } from "react";
import { IKeyPickerProps, KeyPicker } from "./KeyPicker";
import { RedoOutlined } from "@ant-design/icons";
import { IChordPickerProps } from "./ChordPicker";
import {
  MenuOutlined,
  SaveOutlined,
  FolderOpenOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

interface INavBarProps extends IKeyPickerProps, IChordPickerProps {
  resetEverything: () => void;
  openSaveProgressionModal: () => void;
  openProgressionLibraryModal: () => void;
  openShareProgressionLinkModal: () => void;
}

export const NavBar: React.FunctionComponent<INavBarProps> = (props) => {
  const {
    resetEverything,
    openSaveProgressionModal,
    openProgressionLibraryModal,
    openShareProgressionLinkModal,
  } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const breakpoints = Grid.useBreakpoint();
  const shouldCollapseToDrawer = !breakpoints.md;

  const onOpenSaveProgressionModal = () => {
    openSaveProgressionModal();
    setIsDrawerOpen(false);
  };

  const onOpenProgressionLibraryModal = () => {
    openProgressionLibraryModal();
    setIsDrawerOpen(false);
  };

  const onOpenShareProgressionLinkModal = () => {
    openShareProgressionLinkModal();
    setIsDrawerOpen(false);
  };

  const onResetEverything = () => {
    resetEverything();
    setIsDrawerOpen(false);
  };

  const primaryFunctionalityComponents = (
    <>
      <KeyPicker {...props} />
      {/* <ChordPicker {...props} /> */}
      <Button
        onClick={onOpenShareProgressionLinkModal}
        icon={<ShareAltOutlined />}
      >
        Share
      </Button>
      <Button onClick={onOpenSaveProgressionModal} icon={<SaveOutlined />}>
        Save
      </Button>
      <Button
        onClick={onOpenProgressionLibraryModal}
        icon={<FolderOpenOutlined />}
      >
        Library
      </Button>
      <Button onClick={onResetEverything} icon={<RedoOutlined />}>
        Reset
      </Button>
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        style={{ color: "white", fontSize: "1.5rem", fontWeight: 100 }}
      >
        SpicyChords
      </Typography>
      {shouldCollapseToDrawer && (
        <Button icon={<MenuOutlined />} onClick={() => setIsDrawerOpen(true)} />
      )}
      {shouldCollapseToDrawer ? (
        <Drawer
          visible={isDrawerOpen}
          title={"Settings"}
          closable={true}
          placement={"right"}
          onClose={() => setIsDrawerOpen(false)}
          width={"auto"}
        >
          <Space direction={"vertical"}>{primaryFunctionalityComponents}</Space>
        </Drawer>
      ) : (
        <Space direction={"horizontal"}>{primaryFunctionalityComponents}</Space>
      )}
    </div>
  );
};
