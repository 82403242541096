import { notification } from "antd";
import {
  BaseChordType,
  KeyNote,
  ProgressionItem,
} from "./chord-logic/baseChordTypes";

const _baseUrl = "https://spicychords.io";
const _paramOriginalKey = "ok";
const _paramProgressionItems = "pi";
const _chordKeySeparator = "-";
const _progressionItemSeparator = ",";

export class SharingLinks {
  // https://spicychords.io/?ok=C&pi=CMaj7-C,C7-F
  static createProgressionUrl = (
    originalKey: KeyNote,
    progressionItems: ProgressionItem[]
  ): string => {
    let url = _baseUrl + "/?";
    // Add param: original key
    url += _paramOriginalKey + "=" + originalKey;
    url += "&";
    // Add param: progression items
    url +=
      _paramProgressionItems +
      "=" +
      createProgressionItemsString(progressionItems);
    return url;
  };

  static detectProgressionUrl = (): {
    isValid: boolean;
    originalKey?: KeyNote;
    progressionItems?: ProgressionItem[];
  } => {
    let isValid = false;
    const urlParams = new URLSearchParams(window.location.search);
    // Get param: original key
    const originalKey = urlParams.get(_paramOriginalKey) as KeyNote;
    // Get param: progression items
    const piStr = urlParams.get(_paramProgressionItems);
    let progressionItems: ProgressionItem[] = [];
    if (piStr) {
      progressionItems = parseProgressionItems(piStr);
    }

    // Determine if this is a share link or not
    if (progressionItems.length > 0 && !!originalKey) {
      isValid = true;
    }

    return { isValid, originalKey, progressionItems };
  };

  static copyProgressionShareLinkToClipboard = async (
    originalKey: KeyNote,
    progressionItems: ProgressionItem[]
  ) => {
    const shareLink = this.createProgressionUrl(originalKey, progressionItems);
    await navigator.clipboard.writeText(shareLink);
    notification.success({
      message: "Link copied to clipboard!",
      duration: 3,
    });
  };
}

const createProgressionItemsString = (
  progressionItems: ProgressionItem[]
): string => {
  let piStr = "";
  progressionItems.forEach((progression, index) => {
    piStr += progression.baseChordType + _chordKeySeparator + progression.key;

    if (index !== progressionItems.length - 1) {
      piStr += _progressionItemSeparator;
    }
  });
  return piStr;
};

const parseProgressionItems = (piStr: string): ProgressionItem[] => {
  const progressionItems: ProgressionItem[] = [];
  piStr.split(_progressionItemSeparator).forEach((progItem) => {
    const progItemArray = progItem.split(_chordKeySeparator);
    progressionItems.push({
      baseChordType: progItemArray[0] as BaseChordType,
      key: progItemArray[1] as KeyNote,
    });
  });
  return progressionItems;
};
