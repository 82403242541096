import { PageHeader } from "antd";
import React from "react";
import { BaseChord } from "../chord-logic/BaseChord";
import { abcNotationConversions } from "../utils";
import { Notation } from "./Notation";

interface IChordInfoProps {
  uniqueId: string; // Needed to make the abcNotation rendering work
  baseChord: BaseChord;
  currentKey: string;
}

export const ChordInfo: React.FunctionComponent<IChordInfoProps> = (props) => {
  const { uniqueId, baseChord, currentKey } = props;
  const baseChordInfo = baseChord.getBaseChordInfo(currentKey);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <PageHeader
        title={baseChordInfo.symbol}
        subTitle={baseChord.getRomanNumeralSymbol()}
        style={{ paddingRight: 0 }}
      />
      <div style={{ width: "8rem" }}>
        <Notation
          uniqueId={uniqueId}
          abcNotation={abcNotationConversions.notesToSingleChord(
            baseChordInfo.notes
          )}
        />
      </div>
    </div>
  );
};
