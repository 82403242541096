import { Typography } from "antd";
import React from "react";

interface IFooterProps {}

export const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography>
        Made with ❤️ and 🌶️ by{" "}
        <Typography.Link
          target={"_blank"}
          href={"https://www.linkedin.com/in/matthew-schmuckler/"}
        >
          Matthew Schmuckler
        </Typography.Link>
      </Typography>
    </div>
  );
};
