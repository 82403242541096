import React from "react";
import { BaseChord } from "../chord-logic/BaseChord";
import { ChordsGrid, IChordsGridProps } from "./ChordsGrid";

interface INextChordsGridProps
  extends Omit<IChordsGridProps, "chordConnections"> {
  baseChord: BaseChord;
}

export const NextChordsGrid: React.FunctionComponent<INextChordsGridProps> = (
  props
) => {
  const { baseChord } = props;
  const nextChords = baseChord.getNextChords();

  return <ChordsGrid {...props} chordConnections={nextChords} />;
};
