import { Button, Modal } from "antd";
import React from "react";
import { KeyNote } from "../chord-logic/baseChordTypes";
import { SharingLinks } from "../SharingLinks";
import {
  ChordProgressionTracker,
  IChordProgressionTrackerProps,
} from "./ChordProgressionTracker";

interface IShareProgressionLinkModalProps
  extends IChordProgressionTrackerProps {
  isVisible: boolean;
  closeModal: () => void;
  originalKey: KeyNote;
}

export const ShareProgressionLinkModal: React.FunctionComponent<
  IShareProgressionLinkModalProps
> = (props) => {
  const { isVisible, closeModal, originalKey, progressionItems } = props;

  return (
    <Modal
      visible={isVisible}
      onCancel={closeModal}
      closable={true}
      footer={null}
      title={"Share your chord progression"}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          className={"pulsing"}
          onClick={() =>
            SharingLinks.copyProgressionShareLinkToClipboard(
              originalKey,
              progressionItems
            )
          }
          style={{
            color: "white",
            background: "linear-gradient(90deg, #ff6650, #f5ab51)",
            marginBottom: "1rem",
          }}
          size={"large"}
        >
          Copy Link
        </Button>
        <ChordProgressionTracker {...props} />
      </div>
    </Modal>
  );
};
