import { Card, Space, Tag, Typography } from "antd";
import React from "react";
import { BaseChord } from "../chord-logic/BaseChord";
import { ProgressionItem } from "../chord-logic/baseChordTypes";
import { ArrowRightOutlined } from "@ant-design/icons";
import { mapKeyToColorHex } from "../utils";

export interface IChordProgressionTrackerProps {
  progressionItems: ProgressionItem[];
}

export const ChordProgressionTracker: React.FunctionComponent<
  IChordProgressionTrackerProps
> = (props) => {
  const { progressionItems } = props;
  let previousItemKeyTracker: string;
  return (
    <Card>
      {progressionItems.map((progressionItem, index) => {
        const baseChord = new BaseChord(progressionItem.baseChordType);
        let symbolToShow = `${
          baseChord.getBaseChordInfo(progressionItem.key).symbol
        }`;

        // When a key change occurs
        if (progressionItem.key !== previousItemKeyTracker) {
          symbolToShow += ` (${progressionItem.key})`;
        }

        previousItemKeyTracker = progressionItem.key;
        return (
          <Space
            direction={"horizontal"}
            size={0}
            key={`ChordProgressionTracker-${index}-${progressionItem.baseChordType}`}
          >
            <Tag
              color={mapKeyToColorHex(progressionItem.key)}
              style={{ margin: "0.2rem" }}
            >
              <Typography>{symbolToShow}</Typography>
            </Tag>
            <ArrowRightOutlined />
          </Space>
        );
      })}
    </Card>
  );
};
