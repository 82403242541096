export enum KeyNote {
  C = "C",
  G = "G",
  D = "D",
  A = "A",
  E = "E",
  B = "B",
  Fsharp = "F#",
  Csharp = "C#",
  Aflat = "Ab",
  Eflat = "Eb",
  Bflat = "Bb",
  F = "F",
}

export interface ProgressionItem {
  baseChordType: BaseChordType;
  key: KeyNote;
}

export interface ChordConnection {
  baseChordType: BaseChordType;
  description: string;
  keyModulationDistance?: string; // How much to Note.transpose() by
  isHighlyCommon?: boolean;
  spiceLevel?: number;
  suggestedBasedOnPrevChord?: BaseChordType[];
}

export enum BaseChordType {
  Io7 = "Io7",
  I = "I",
  I6 = "I6",
  I69 = "I6/9",
  IMaj7 = "IMaj7",
  Im7 = "Im7",
  I7 = "I7",
  Iaug = "Iaug",
  bIIo = "bIIo",
  bII7 = "bII7",
  IIminMaj7 = "IIm/M7",
  IIm6 = "IIm6",
  IIm7 = "IIm7",
  IIm9 = "IIm9",
  II7 = "II7",
  bIIIo = "bIIIo",
  IIIm7 = "IIIm7",
  IVMaj7 = "IVMaj7",
  IVm7 = "IVm7",
  IVm6 = "IVm6",
  V7 = "V7",
  V7aug = "V7aug",
  V7b9 = "V7b9",
  V7sharp9 = "V7#9",
  V9sus4 = "V9sus4",
  VIm7 = "VIm7",
  VI7 = "VI7",
  VIm = "VIm",
  VIIm7b5 = "VIIm7b5",
}

// All Chord Symbols
// "5"
// "M7#5sus4"
// "7#5sus4"
// "sus4"
// "M7sus4"
// "7sus4"
// "7no5"
// "aug"
// "M7b6"
// "maj7#5"
// "7#5"
// "7b13"
// "M"
// "maj7"
// "7"
// "6"
// "7add6"
// "7b6"
// "Mb5"
// "M7b5"
// "7b5"
// "maj#4"
// "7#11"
// "M6#11"
// "7#11b13"
// "m#5"
// "mb6M7"
// "m7#5"
// "m"
// "m/ma7"
// "m7"
// "m6"
// "mMaj7b6"
// "dim"
// "oM7"
// "m7b5"
// "dim7"
// "o7M7"
// "4"
// "madd4"
// "m7add11"
// "+add#9"
// "7#5#9"
// "7#9"
// "13#9"
// "7#9b13"
// "maj7#9#11"
// "7#9#11"
// "13#9#11"
// "7#9#11b13"
// "sus2"
// "M9#5sus4"
// "sus24"
// "M9sus4"
// "11"
// "9sus4"
// "13sus4"
// "9no5"
// "13no5"
// "M#5add9"
// "maj9#5"
// "9#5"
// "9b13"
// "Madd9"
// "maj9"
// "9"
// "6/9"
// "maj13"
// "M7add13"
// "13"
// "M9b5"
// "9b5"
// "13b5"
// "9#5#11"
// "maj9#11"
// "9#11"
// "69#11"
// "M13#11"
// "13#11"
// "9#11b13"
// "m9#5"
// "madd9"
// "mM9"
// "m9"
// "m69"
// "m13"
// "mMaj9b6"
// "m9b5"
// "m11A"
// "m11"
// "b9sus"
// "11b9"
// "7sus4b9b13"
// "alt7"
// "7#5b9"
// "Maddb9"
// "M7b9"
// "7b9"
// "13b9"
// "7b9b13"
// "7#5b9#11"
// "7b9#11"
// "13b9#11"
// "7b9b13#11"
// "mb6b9"
// "7b9#9"
