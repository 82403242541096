import { AbcNotation, Note } from "@tonaljs/tonal";
import {
  BaseChordType,
  ChordConnection,
  KeyNote,
} from "./chord-logic/baseChordTypes";

export const calculateKeyFromConnection = (
  currentKey: KeyNote,
  chordConnection: ChordConnection
): KeyNote => {
  let returnedKey = currentKey;
  if (chordConnection.keyModulationDistance) {
    returnedKey = Note.transpose(
      currentKey,
      chordConnection.keyModulationDistance
    ) as KeyNote;
  }

  return simplifyEnharmonicKeys(returnedKey);
};

export const getAllBaseChordTypes = () => {
  return Object.values(BaseChordType);
};

export const getAllKeys = () => {
  return Object.values(KeyNote);
};

export const mapKeyToColorHex = (key: KeyNote): string => {
  switch (key) {
    case KeyNote.C:
      return "#FAEC2B";
    case KeyNote.G:
      return "#F8BF31";
    case KeyNote.D:
      return "#F29532";
    case KeyNote.A:
      return "#EE6930";
    case KeyNote.E:
      return "#EB2B2E";
    case KeyNote.B:
      return "#A12E65";
    case KeyNote.Fsharp:
      return "#572D91";
    case KeyNote.Csharp:
      return "#136FB6";
    case KeyNote.Aflat:
      return "#02A6E3";
    case KeyNote.Eflat:
      return "#02A69D";
    case KeyNote.Bflat:
      return "#00A056";
    case KeyNote.F:
      return "#93C14B";
  }
};

export const abcNotationConversions = {
  notesToSingleChord: (notes: string[]): string => {
    let chordNotesString = "";
    notes.forEach((note) => {
      // The "4" specifies the middle register of the treble cleft
      const abcNoteString = AbcNotation.scientificToAbcNotation(`${note}4`);
      chordNotesString += abcNoteString;
    });
    return `[${chordNotesString}]2|`;
  },
};

const simplifyEnharmonicKeys = (key: KeyNote): KeyNote => {
  switch (key) {
    case "Cb" as KeyNote:
      return KeyNote.B;
    case "Gb" as KeyNote:
      return KeyNote.Fsharp;
    case "Db" as KeyNote:
      return KeyNote.Csharp;
    case "D#" as KeyNote:
      return KeyNote.Eflat;
    case "A#" as KeyNote:
      return KeyNote.Bflat;
    case "G#" as KeyNote:
      return KeyNote.Aflat;
    case "E#" as KeyNote:
      return KeyNote.F;
    default:
      return key;
  }
};
