import { BaseChordType, ChordConnection } from "./baseChordTypes";

export const generateChordMappings = (
  baseChordType: BaseChordType
): { nextChords: ChordConnection[]; alternativeChords: ChordConnection[] } => {
  switch (baseChordType) {
    case BaseChordType.Io7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IMaj7,
            description:
              "Resolves back to the I in a dreamy, disney-princess waltz like manner",
            suggestedBasedOnPrevChord: [
              BaseChordType.V7,
              BaseChordType.V7b9,
              BaseChordType.V7aug,
              BaseChordType.IVm6,
              BaseChordType.bII7,
              BaseChordType.V9sus4,
            ],
          },
          {
            baseChordType: BaseChordType.IIm7,
            description:
              "Treats this diminished chord as a passing chord in a 2-5-1",
          },
          {
            baseChordType: BaseChordType.IIm7,
            description: "Modulate key with a II chord",
            keyModulationDistance: "6M",
          },
          {
            baseChordType: BaseChordType.IIIm7,
            description: "Modulate key with a III chord",
            keyModulationDistance: "5M",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.I:
    case BaseChordType.I69:
    case BaseChordType.IMaj7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IIm7,
            description: "A catchy, stepwise progression",
          },
          {
            baseChordType: BaseChordType.VIm7,
            description: "A bit sombre but still jazzy and well-intentioned",
          },
          {
            baseChordType: BaseChordType.I6,
            description: "Heartwarming and still resolved",
          },
          {
            baseChordType: BaseChordType.V7,
            description:
              "To the dominant and probably back again. Simple 2 chord progression",
          },
          {
            baseChordType: BaseChordType.IIIm7,
            description: "A iii-VI7 turnaround on the way to ii",
          },
          {
            baseChordType: BaseChordType.IVMaj7,
            description: "Very bright and cheerful",
          },
          {
            baseChordType: BaseChordType.II7,
            description: "Take the A Train progression",
          },
          {
            baseChordType: BaseChordType.I7,
            description: "My Way progression, downard movement from the maj7",
          },
          {
            baseChordType: BaseChordType.Iaug,
            description: "Showtunes-y feeling with upward movement",
          },
          {
            baseChordType: BaseChordType.bIIo,
            description: "A passing chord upwards",
          },
          {
            baseChordType: BaseChordType.Io7,
            description:
              "A dissonant way to move from the I chord, on your way to modulating keys or a 2-5-1",
          },
          {
            baseChordType: BaseChordType.IIm7,
            description:
              "Make your current I chord minor, and treat it as the ii of new key",
            keyModulationDistance: "14m",
          },
          {
            baseChordType: BaseChordType.V7,
            description: "Modulate key with a dominant",
            keyModulationDistance: "4M",
          },
          {
            baseChordType: BaseChordType.IIm7,
            description: "Modulate key with a II chord",
            keyModulationDistance: "4M",
          },
        ],
        alternativeChords: [
          {
            baseChordType: BaseChordType.I,
            description: "A basic triad with a simple sound",
          },
          {
            baseChordType: BaseChordType.I69,
            description: "Resolved yet slightly dissonant, a latin jazz vibe",
            spiceLevel: 2,
          },
          {
            baseChordType: BaseChordType.IMaj7,
            description: "The golden standard of all root chords",
          },
        ],
      };
    case BaseChordType.I6:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IMaj7,
            description:
              "Top note of chord ascending upwards (First chord should be a basic triad without 7th)",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.Iaug:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.VIm,
            description:
              "Continue upward movement, then go to dominant and modulate key",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.I7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IVMaj7,
            description:
              "Resolves to the IV",
          },
          {
            baseChordType: BaseChordType.VI7,
            description:
              "My Way progression, subverts dominant expectation and instead is going toward ii",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.bIIo:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IMaj7,
            description: "Down chromatically",
            suggestedBasedOnPrevChord: [BaseChordType.IIm7, BaseChordType.IIm9],
          },
          {
            baseChordType: BaseChordType.IIm7,
            description: "Up chromatically",
            suggestedBasedOnPrevChord: [
              BaseChordType.I,
              BaseChordType.I6,
              BaseChordType.I69,
              BaseChordType.IMaj7,
            ],
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.IIm7:
    case BaseChordType.IIm9:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.V7,
            description: "The classic dominant",
            isHighlyCommon: true,
            suggestedBasedOnPrevChord: [BaseChordType.IIminMaj7],
          },
          {
            baseChordType: BaseChordType.IIminMaj7,
            description:
              "A minor major 7th chord. Top note descending downards, on the way to IIm7 (Assume this was a triad)",
          },
          {
            baseChordType: BaseChordType.bIIIo,
            description: "A passing chord upwards",
          },
          {
            baseChordType: BaseChordType.bIIo,
            description: "A passing chord downards",
          },
        ],
        alternativeChords: [
          {
            baseChordType: BaseChordType.IIm7,
            description: "The classic II chord",
          },
          {
            baseChordType: BaseChordType.IIm9,
            description: "A richer and brighter feel than the 7th",
            spiceLevel: 1,
          },
        ],
      };
    case BaseChordType.IIminMaj7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IIm7,
            description: "Continue descending downwards on the top note",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.II7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IIm7,
            description: "'Take the A Train' & 'Girl From Ipanema' progression",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.bIIIo:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IIm7,
            description: "Down chromatically",
            suggestedBasedOnPrevChord: [BaseChordType.IIIm7],
          },
          {
            baseChordType: BaseChordType.IIIm7,
            description: "Up chromatically",
            suggestedBasedOnPrevChord: [BaseChordType.IIm7, BaseChordType.IIm9],
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.IIIm7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.VIm7,
            description: "Down a fifth",
            suggestedBasedOnPrevChord: [BaseChordType.IVm7],
          },
          {
            baseChordType: BaseChordType.VI7,
            description:
              "Down a fifth but dominant, adds more tension and forward momentum on the way to the ii chord",
            suggestedBasedOnPrevChord: [BaseChordType.IMaj7],
          },
          {
            baseChordType: BaseChordType.bIIIo,
            description: "A passing chord downwards",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.IVMaj7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IVm7,
            description: "Used often by the Beatles",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.IVm7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IIIm7,
            description: "Progresses the downward movement",
            isHighlyCommon: true,
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.V7:
    case BaseChordType.V7b9:
    case BaseChordType.V7sharp9:
    case BaseChordType.V7aug:
    case BaseChordType.IVm6:
    case BaseChordType.bII7:
    case BaseChordType.V9sus4:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IMaj7,
            description: "The classic resolution",
            isHighlyCommon: true,
          },
          {
            baseChordType: BaseChordType.IIIm7,
            description: "Subvert expectation to continue the phrase",
          },
          {
            baseChordType: BaseChordType.Io7,
            description:
              "A passing diminished chord on the way to I. Very waltzy and disney-princess like",
          },
          {
            baseChordType: BaseChordType.V7,
            description: "A blues-y way to continuously modulate keys",
            keyModulationDistance: "4M",
          },
        ],
        alternativeChords: [
          {
            baseChordType: BaseChordType.IVm6,
            description: "A more sorrowful and mysterious sound",
          },
          {
            baseChordType: BaseChordType.V7b9,
            description: "More dissonant and juicy",
            spiceLevel: 1,
          },
          {
            baseChordType: BaseChordType.V7sharp9,
            description: "Very dissonant and tangy, perhaps even a little sour",
            spiceLevel: 2,
          },
          {
            baseChordType: BaseChordType.V7aug,
            description: "Has a showtunes-y feel",
            spiceLevel: 1,
          },
          {
            baseChordType: BaseChordType.bII7,
            description: "A tritone substitution",
          },
          {
            baseChordType: BaseChordType.V7,
            description: "The classic dominant",
          },
          {
            baseChordType: BaseChordType.V9sus4,
            description:
              "Also called 4 over 5 (IV/V). Very common in showtunes theatre, where the fifth note of the V is dropped",
          },
        ],
      };
    case BaseChordType.VIm:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.I7,
            description: "Continue chromatically upward",
          },
          {
            baseChordType: BaseChordType.V7,
            description:
              "Continue chromatically upward and modulate to a different key",
            keyModulationDistance: "4M",
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.VIm7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IIm7,
            description: "Down a fifth",
            isHighlyCommon: true,
          },
        ],
        alternativeChords: [],
      };
    case BaseChordType.VI7:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IIm7,
            description: "Down a fifth, with extra resolution",
            isHighlyCommon: true,
          },
        ],
        alternativeChords: [],
      };
    default:
      return {
        nextChords: [
          {
            baseChordType: BaseChordType.IMaj7,
            description: "No connections mapped yet!",
          },
        ],
        alternativeChords: [],
      };
  }
};
