import { Button, List, Modal, Typography } from "antd";
import React, { useState } from "react";
import { LocalStorageDB, SavedProgression } from "../localStorage";
import { DeleteOutlined, ShareAltOutlined } from "@ant-design/icons";
import { SharingLinks } from "../SharingLinks";

interface IProgressionLibraryModalProps {
  isVisible: boolean;
  closeModal: () => void;
  loadProgression: (progression: SavedProgression) => void;
}

export const ProgressionLibraryModal: React.FunctionComponent<
  IProgressionLibraryModalProps
> = (props) => {
  const { isVisible, closeModal, loadProgression } = props;
  const [deleteForceRerender, setDeleteForceRerender] = useState<number>(0);
  const savedProgressions = LocalStorageDB.getAllSavedProgressions();
  const onDeleteProgression = (name: string) => {
    LocalStorageDB.deleteSavedProgression(name);
    setDeleteForceRerender(deleteForceRerender + 1);
  };
  const onLoadProgression = (progression: SavedProgression) => {
    loadProgression(progression);
    closeModal();
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={closeModal}
      closable={true}
      title={"My chord progressions"}
      footer={null}
    >
      {savedProgressions.length === 0 ? (
        <Typography>No progressions saved</Typography>
      ) : (
        <List>
          {savedProgressions.map((progression) => {
            return (
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => onLoadProgression(progression)}
                key={`ProgressionLibraryModal-ListItem-${progression.name}`}
                actions={[
                  <Button
                    icon={<ShareAltOutlined />}
                    onClick={() =>
                      SharingLinks.copyProgressionShareLinkToClipboard(
                        progression.originalKey,
                        progression.progressionItems
                      )
                    }
                  />,
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => onDeleteProgression(progression.name)}
                  />,
                ]}
              >
                {progression.name}
              </List.Item>
            );
          })}
        </List>
      )}
    </Modal>
  );
};
